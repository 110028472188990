<template>
  <v-app class="wmyHomeApp">
    <v-banner
      single-line
      class="banner"
    >
      <v-carousel class="h5-carousel-new"
                  :show-arrows="false" :cycle="true"
                  hide-delimiter-background>
        <v-carousel-item v-for="(v,index) in bannerList" :key="index">
          <v-img :src="v.img" width="100%" contain class="d-flex align-center">
            <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            <v-card-title class="ml-6 pa-0 font-size-h5banner-title white-space-pre-wrap color-FFF" :class="v.btn ? 'mt-8':''">{{v.title}}</v-card-title>
            <v-card-text class="mt-4 ml-6 pa-0 font-size-h5banner-tip white-space-pre-wrap color-FFF" style="width: 50%;">{{v.tip}}</v-card-text>
            <v-card-actions class="pa-0 mt-3 ml-6" v-if="v.btn">
              <v-btn
                  rounded
                  color="#FFFFFF"
                  class="font-size-h5banner-btn"
                  target="_blank"
                  @click.stop="$emit('handleSuspend','agent')"
                  style="color: #2672FF"
              >{{v.btn}}
              </v-btn>
            </v-card-actions>
          </v-img>
        </v-carousel-item>
      </v-carousel> 
    </v-banner>
    <div class="width-per-100 px-4 mt-3">
      <v-row class="pa-0">
        <v-col v-for="(menu,index) in menuList" :key="index" class="pa-2" cols="6">
          <v-card class="menu-card py-4" flat color="white" :to="menu.router">
            <div class="d-flex justify-center">
              <v-img max-width="50" contain :src="menu.icon"></v-img>
            </div>
            <v-card-title class="pa-0 mt-4 justify-center font-size-15">{{menu.title}}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!--&lt;!&ndash; 互动游戏精选 &ndash;&gt;-->
    <!--<div class="width-per-100 pb-3 px-4">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0 mt-6">互动游戏精选</v-card-title>-->
    <!--  <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">图像、物体、面部等多种识别方式 互动体验更强</v-card-text>-->
    <!--  <div style="margin-top: 6px;">-->
    <!--    <v-row class="pa-0">-->
    <!--      <v-col :cols="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 6 : 3"-->
    <!--             class="d-flex justify-center pa-2"-->
    <!--             v-for="(game,colIndex) in arShowAnli" :key="colIndex">-->
    <!--        <v-card flat class="h5-card" width="100%" @click.stop="handleAnli(game)">-->
    <!--          <v-img max-width="100%" contain :src="game.coverImg"></v-img>-->
    <!--          <v-card-title class="pa-3 font-size-15 line-height-22">-->
    <!--            {{game.itemTitle}}-->
    <!--          </v-card-title>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </div>-->
    <!--</div>-->
    <!--&lt;!&ndash;换一换&ndash;&gt;-->
    <!--<div class="width-per-100 d-flex justify-space-between px-6">-->
    <!--  <div style="width: 50px;"></div>-->
    <!--  <v-btn width="80" height="30" color="#3F8CFF"-->
    <!--         depressed rounded :ripple="false"-->
    <!--         @click.stop="handleRandomAnli('ar')">-->
    <!--    <span style="color: #FFFFFF;font-size: 12px;">换一换</span>-->
    <!--  </v-btn>-->
    <!--  <div class="line-height-30" style="width: 50px;" @click.stop="handleAllAnli(0)">更多 >></div>-->
    <!--</div>-->
    <!--&lt;!&ndash; 全景精选 &ndash;&gt;-->
    <!--<div class="width-per-100 pb-3 px-4 mt-10">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">全景精选</v-card-title>-->
    <!--  <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">遇见VR全景，颠覆传统展现形式，科技改变生活</v-card-text>-->
    <!--  <div style="margin-top: 6px;">-->
    <!--    <v-row class="pa-0">-->
    <!--      <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 6 : 3"-->
    <!--             class="d-flex justify-center pa-2"-->
    <!--             v-for="(game,colIndex) in vrShowAnli" :key="colIndex">-->
    <!--        <v-card flat class="h5-card" width="100%" @click.stop="handleAnli(game)">-->
    <!--          <v-img max-width="100%" contain :src="game.coverImg"></v-img>-->
    <!--          <v-card-title class="pa-3 font-size-15 line-height-22">-->
    <!--            {{game.itemTitle}}-->
    <!--          </v-card-title>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </div>-->
    <!--</div>-->
    <!--&lt;!&ndash;换一换&ndash;&gt;-->
    <!--<div class="width-per-100 d-flex justify-space-between px-6">-->
    <!--  <div style="width: 50px;"></div>-->
    <!--  <v-btn width="80" height="30" color="#3F8CFF"-->
    <!--         depressed rounded :ripple="false"-->
    <!--         @click.stop="handleRandomAnli('vr')">-->
    <!--    <span style="color: #FFFFFF;font-size: 12px;">换一换</span>-->
    <!--  </v-btn>-->
    <!--  <div class="line-height-30" style="width: 50px;" @click.stop="handleAllAnli(1)">更多 >></div>-->
    <!--</div>-->
    <!--&lt;!&ndash; 全景拍摄精选 &ndash;&gt;-->
    <!--<div class="width-per-100 pb-3 px-4 mt-10">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">全景拍摄精选</v-card-title>-->
    <!--  <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">为有VR需求企业提供拍摄、制作、后期一体化服务</v-card-text>-->
    <!--  <div style="margin-top: 6px;">-->
    <!--    <v-row class="pa-0">-->
    <!--      <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 6 : 3"-->
    <!--             class="d-flex justify-center pa-2"-->
    <!--             v-for="(game,colIndex) in shotShowAnli" :key="colIndex">-->
    <!--        <v-card flat class="h5-card" width="100%" @click.stop="handleAnli(game)">-->
    <!--          <v-img max-width="100%" contain :src="game.coverImg"></v-img>-->
    <!--          <v-card-title class="pa-3 font-size-15 line-height-22">-->
    <!--            {{game.itemTitle}}-->
    <!--          </v-card-title>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </div>-->
    <!--</div>-->
    <!--&lt;!&ndash;换一换&ndash;&gt;-->
    <!--<div class="width-per-100 d-flex justify-space-between px-6">-->
    <!--  <div style="width: 50px;"></div>-->
    <!--  <v-btn width="80" height="30" color="#3F8CFF"-->
    <!--         depressed rounded :ripple="false"-->
    <!--         @click.stop="handleRandomAnli('shot')">-->
    <!--    <span style="color: #FFFFFF;font-size: 12px;">换一换</span>-->
    <!--  </v-btn>-->
    <!--  <div class="line-height-30" style="width: 50px;" @click.stop="handleAllAnli(6)">更多 >></div>-->
    <!--</div>-->
    <!--&lt;!&ndash; 3D精选 &ndash;&gt;-->
    <!--<div class="width-per-100 pb-3 px-4 mt-10">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">3D场景</v-card-title>-->
    <!--  <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">点燃你的创意火花</v-card-text>-->
    <!--  <div style="margin-top: 6px;">-->
    <!--    <v-row class="pa-0">-->
    <!--      <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 6 : 3"-->
    <!--             class="d-flex justify-center pa-2"-->
    <!--             v-for="(game,colIndex) in threeDMaxShowAnli" :key="colIndex">-->
    <!--        <v-card flat class="h5-card" width="100%" @click.stop="handleAnli(game)">-->
    <!--          <v-img max-width="100%" contain :src="game.coverImg"></v-img>-->
    <!--          <v-card-title class="pa-3 font-size-15 line-height-22">-->
    <!--            {{game.itemTitle}}-->
    <!--          </v-card-title>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </div>-->
    <!--</div>-->
    <!--&lt;!&ndash;换一换&ndash;&gt;-->
    <!--<div class="width-per-100 d-flex justify-space-between px-6">-->
    <!--  <div style="width: 50px;"></div>-->
    <!--  <v-btn width="80" height="30" color="#3F8CFF"-->
    <!--         depressed rounded :ripple="false"-->
    <!--         @click.stop="handleRandomAnli('threeDMax')">-->
    <!--    <span style="color: #FFFFFF;font-size: 12px;">换一换</span>-->
    <!--  </v-btn>-->
    <!--  <div class="line-height-30" style="width: 50px;" @click.stop="handleAllAnli(3)">更多 >></div>-->
    <!--</div>-->
    <!-- 我们的优势 -->
    <!--<div class="width-per-100 pb-3 px-4 mt-10">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">我们的优势</v-card-title>-->
    <!--  <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">5G时代 + 政策扶持时代机遇，顺势而为</v-card-text>-->
    <!--  <v-tabs v-model="advantageTab" background-color="transparent" color="white" dark centered hide-slider-->
    <!--          class="xs-tab">-->
    <!--    <v-tab v-for="(v, k) in advantageList" :key="k" style="min-width: unset;padding: 0 5px;">-->
    <!--      <div class="tab-name-btn" :class="advantageTab === k ? 'tab-active':''">{{ v.title }}</div>-->
    <!--    </v-tab>-->
    <!--  </v-tabs>-->
    <!--  <v-tabs-items class="mt-3 mx-1 transparent" v-model="advantageTab" style="border-radius: 10px;">-->
    <!--    <v-tab-item v-for="(v, k) in advantageList" :key="k" :transition="false">-->
    <!--      <v-card flat height="100%" class="pa-5 pb-0">-->
    <!--        <div v-for="(info,index) in v.children" :key="index" class="">-->
    <!--          <v-card-title class="pa-0 font-size-15 line-height-16 font-weight-bold">-->
    <!--            <div class="tip_label"></div>-->
    <!--            {{info.title}}-->
    <!--          </v-card-title>-->
    <!--          <v-card-text class="pa-0 pb-5 mt-2 line-height-17 color-666 font-size-12">{{info.tip}}</v-card-text>-->
    <!--        </div>-->
    <!--      </v-card>-->
    <!--    </v-tab-item>-->
    <!--  </v-tabs-items>-->
    <!--</div>-->
    
    <!--<div class="width-per-100 pb-3 px-4 mt-10">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">合作伙伴</v-card-title>-->
    <!--  <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">他们都在用</v-card-text>-->
    <!--  <v-img class="mt-3" width="100%" contain src="https://h5.ophyer.cn/official_website/other/h5Home05.png">-->
    <!--    <template v-slot:placeholder><img-placeholder></img-placeholder></template>-->
    <!--  </v-img>-->
    <!--</div>-->
  </v-app>
</template>

<script>
import textData from '@/static/textData.js'
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/h5WmyHome-banner01.png",
          // title: "数字商品+虚拟现实\n构建运营“元宇宙”",
          // title: "数字商品+虚拟现实\n构建企业数字化运营",
          title: "数字商品+虚拟现实\n构建企业数字化运营",
          tip: "虚拟、超沉浸体验，\n重塑品牌影响力",
        },
        {
          img: "https://h5.ophyer.cn/official_website/banner/h5WmyHome-banner02.png",
          title: "VR带看\n全新升级",
          // tip: "互动演示“零”距离，\n运营更近一步"
          tip: "互动演示“零”距离，\n打破空间和距离"
        },
        {
          img: "https://h5.ophyer.cn/official_website/banner/h5WmyHome-banner03.png",
          title: "诚邀您的加入",
          tip: "多重代理收益、培训支持、驻地支持、市场支持、服务支持、大客陪跑",
          btn: "我要代理"
        }
      ],
      menuList: [
        {
          // title: "AR 创意运营",
          title: "AR 创意互动",
          icon: require("../../../../public/img/icons/menu/arCreativeMarketing.png"),
          router:"/wmyH5/wmyH5solutionAR"
        },
        {
          title: "VR 全景网站",
          icon: require("../../../../public/img/icons/menu/vrPanorama.png"),
          router:"/wmyH5/wmyH5VRpanoramaWebsite"
        },
        {
          // title: "大客户定制运营",
          title: "大客户定制",
          icon: require("../../../../public/img/icons/menu/customers.png"),
          router:"/wmyH5/wmyH5BigCustomer"
        },
        // {
        //   title: "招商加盟",
        //   icon: require("../../../../public/img/icons/menu/cooperation.png"),
        //   router:"/wmyH5/agent"
        // }
      ],
      arAnli: textData.CooperationCase[0].tab[0].tabItem,
      arShowAnli: [],
      arLastIndex: 0,
      vrAnli: textData.CooperationCase[1].tab[0].tabItem,
      vrShowAnli: [],
      vrLastIndex: 0,
      threeDMaxAnli: textData.CooperationCase[4].tab[0].tabItem,
      threeDMaxShowAnli: [],
      threeDMaxLastIndex: 0,
      shotAnli: textData.CooperationCase[3].tab[0].tabItem,
      shotShowAnli: [],
      shotLastIndex: 0,
      advantageTab: 0,
      advantageList: [
        {
          title: "市场前景广阔",
          children: [
            {
              title: "政策扶持",
              tip: "近年来国家不断推出政策、发展基金等助推产业发展；国家十四五规划将AR/VR技术正式写入数字经济重点产业领域"
            },
            {
              title: "5G+ 时代",
              tip: "5G时代来临，信息网络更加快速、稳定成熟，为AR/VR的发展提供了良好的契机"
            },
            {
              title: "产业聚集",
              tip: "随着VR技术的更迭，VR产业逐渐形成规模化、应用化，市场需求量骤增"
            }
          ]
        },
        {
          title: "稳定技术支持",
          children: [
            {
              title: "技术基础坚实",
              tip: "10年技术沉淀，深耕AR/VR、AI及运营领域，在国内的AR/VR智慧运营领域名列前茅"
            },
            {
              title: "全栈自研技术",
              tip: "独立自主研发AR/VR引擎 ｜ 累计获得软件著作权234个，授权专利4个，作品著作权21个"
            },
            {
              title: "稳定开发团队",
              tip: "100 + 技术团队强有力支撑，团队成员稳定，为系统开发升级保驾护航"
            }
          ]
        },
        {
          title: "全面服务保障",
          children: [
            {
              title: "智能数据分析",
              tip: "运营活动效果监控，实时追踪活动数据，为运营方案调整提供数据支持"
            },
            {
              title: "多渠道在线客服",
              tip: "7x24小时应急响应，只要您需要，我们随时在身边"
            },
            {
              title: "顾问式运营服务",
              tip: "项目全链路追踪，全程指导，为运营活动持续助力"
            }
          ]
        },
      ],
    }
  },
  created() {
  },
  mounted() {
    this.arShowAnli = this.arAnli.slice(0,4);
    this.vrShowAnli = this.vrAnli.slice(0,4);
    this.threeDMaxShowAnli = this.threeDMaxAnli.slice(0,4);
    this.shotShowAnli = this.shotAnli.slice(0,4);
    this.arLastIndex = this.vrLastIndex = this.threeDMaxLastIndex = this.shotLastIndex = 3;
  },
  watch: {
  },
  methods: {
    handleAllAnli: function (type){
      console.log(type)
      this.$router.push({
        path: '/wmyH5/wmyH5Case',
        query: {
          menuTab: type
        }
      })
    },
    handleAnli: function (info){
      this.$router.push({
        name: 'wmyH5h5CaseDetail',
        query: {
          source: 'qita',
          anli: info.self,
        }
      })
    },
    handleRandomAnli: function (anliName){
      const anliList = this[anliName + "Anli"];
      if(anliList && anliList.length > 4){
        let result = [];
        let lastIndex = this[anliName + "LastIndex"];
        for(;result.length<4;){
          lastIndex++;
          if(!anliList[lastIndex]){
            lastIndex = 0;
          }
          result.push(anliList[lastIndex]);
        }
        this[anliName + "ShowAnli"] = result;
        this[anliName + "LastIndex"] = lastIndex;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.wmyHomeApp{
  background: #F5F8FE;
}
.menu-card{
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
}
</style>
